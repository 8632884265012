export default class AdministrativeFilesIndex {

    constructor() {
        this.validation = null;
        this.validation_password = null;
        this.init();
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }

    init(){

        $.validator.addMethod("password_validation", function( value, element ) {
            return value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,70}$/g) && value.length >= 8;
        }, I18n.t('common.js_validation.password_validation'));

        this.validation_personal_information();
        this.validation_change_password();
    }

    bindEvents() {
        let self = this;
        $("#driving_instructor_profile_image").change(function (){
            self.handleFiles(this.files);
        });

        $(document).ready(function (){
            self.validation.form();
        })

        $("#driving_instructor_instructor_description").keyup(function (e){
            let length = $(this).val().length;
            let label = $("label[for=driving_instructor_instructor_description]");

            if(length >= 1200){
                label.addClass("text-danger")
            }else{
                label.removeClass("text-danger")
            }
            label.text(I18n.t("monitors.administrative_files.personal_info.description", {number: length}))
        })

        $('.upload_file').click(this.openUpload);

        $("input[id^=id_file_]").change(this.handleDocs);
    }

    onDataChanged(data) {
    }

    onDestroy() {
    }

    handleFiles(files) {
        let file = files[0]
        if(this.valid_file(file)){
            this.previewFile(file);
        }
    }

    previewFile(file) {
        let reader = new FileReader();

        reader.onload = function(e) {
            $('#profile_image_preview').attr('src', e.target.result);
        }
        //this.file = file;
        reader.readAsDataURL(file); // convert to base64 string
    }

    validation_personal_information() {
        let validation_options = {
            rules: {
                "driving_instructor[phone_number]":{
                    required: true,
                    phone_number_fr: true
                },
                "driving_instructor[last_name]":{
                    required: true
                },
                "driving_instructor[first_name]":{
                    required: true
                },
                "driving_instructor[birth_date]":{
                    required: true
                },
                "driving_instructor[birth_place]":{
                    required: true
                },
                "driving_instructor[email]":{
                    required: true,
                    email: true
                },
                "driving_instructor[address_line1]":{
                    required: true
                },
                "driving_instructor[instructor_description]":{
                    required: true
                },
                "driving_instructor[car_description]":{
                    required: true
                },
                "driving_instructor[driving_transmission_type]":{
                    required: true
                },
                "driving_instructor[city_id]":{
                    required: true
                },
                "driving_instructor[start_address]":{
                    required: true
                },

            },
            errorClass: 'is-invalid',
            errorElement : 'div',
            errorLabelContainer: '.invalid-feedback',
            ignore: [],
            focusInvalid: false,
            invalidHandler: function(form, validator) {

                if (!validator.numberOfInvalids())
                    return;


                $('html, body').animate({
                    scrollTop: $(validator.errorList[0].element).parent().offset().top - 100
                }, 1000);

            }
        }

        if(I18n.locale !== 'en'){
            validation_options.messages = require("jquery-validation/dist/localization/messages_"+I18n.locale);
        }

        this.validation = $("#files_form").validate(validation_options);
    }

    validation_change_password() {

        let validation_options = {
            rules: {
                "driving_instructor[current_password]":{
                    required: true
                },
                "driving_instructor[password]":{
                    required: true,
                    password_validation: true
                },
                "driving_instructor[password_confirmation]":{
                    required: true,
                    equalTo: "#driving_instructor_password"
                }
            },
            errorClass: 'is-invalid',
            errorElement : 'div',
            errorLabelContainer: '.invalid-feedback',
            ignore: [],
            focusInvalid: false,
            invalidHandler: function(form, validator) {

                if (!validator.numberOfInvalids())
                    return;


                $('html, body').animate({
                    scrollTop: $(validator.errorList[0].element).parent().offset().top - 100
                }, 1000);

            }
        }

        if(I18n.locale !== 'en'){
            validation_options.messages = require("jquery-validation/dist/localization/messages_"+I18n.locale);
        }

        this.validation_password = $("#monitor_password").validate(validation_options);
    }


    openUpload(e) {
        let $el = $(e.currentTarget);
        if(!$el.hasClass('uploaded') && !$el.hasClass('ephoto')){
            $('#'+$el.data('input-id')).click();
        }
    }


    handleDocs(e) {
        let self = application.current_handler;
        let el = e.currentTarget;

        let id = $(el).data('id');
        let $doc = $('#document_'+id);
        $doc.find('.ln-upload').addClass('d-none');
        $doc.find('.spinner-border').removeClass('d-none');

        let formData = new FormData();
        formData.append("driving_instructor_document[document]", el.files[0]);
        let ajaxData = {
            url: '/monitor/administrative_files/'+$(el).data("id")+'/upload_file',
            data : formData,
            method: "POST",
            async: true,
            cache: false,
            contentType: false,
            processData: false,
            timeout: 60000
        };

        $.ajax(ajaxData)
            .done(function(data) {
                let $doc = $('#document_'+data['element_id']);
                $doc.removeClass('file_required').addClass('uploaded');
                $doc.find('.ln-upload').removeClass('d-none');
                $doc.find('.spinner-border').addClass('d-none');
            })
            .fail(function(err) {
                let data = err.responseJSON;
                let $doc = $('#document_'+data['element_id']);
                $doc.addClass('file_required').removeClass('uploaded');
                $('#error_message_'+data['element_id']).text(data['error_message']);
                $doc.find('.ln-upload').removeClass('d-none');
                $doc.find('.spinner-border').addClass('d-none');
            });
    }

    valid_file(file) {
        if(!['image/jpg','image/jpeg','image/png'].includes(file.type)){
            $("#driving_instructor_profile_image").val('');
            return false;
        }else{
            return true;
        }
    }

}