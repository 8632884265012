import ConfigRoutes from "../../config/Routes"
import StudentsShow from "../controllers/StudentsShow";
import PlanningIndex from "../controllers/PlanningIndex";
import AdministrativeFilesIndex from "../controllers/AdministrativeFilesIndex";
import ContactTeamIndex from "../controllers/ContactTeamIndex";
import StudentsIndex from "../controllers/StudentsIndex";
import StudentDevise from "../../student/controllers/StudentDevise";

export default class Routes extends ConfigRoutes{

    static routes = {
        "MonitorsStudentsShow": StudentsShow,
        "MonitorsPlanningIndex": PlanningIndex,
        "MonitorsAdministrativeFilesIndex": AdministrativeFilesIndex,
        "MonitorsAdministrativeFilesUpdate": AdministrativeFilesIndex,
        "MonitorsAdministrativeFilesUpdatePassword": AdministrativeFilesIndex,
        "MonitorsContactTeamIndex": ContactTeamIndex,
        "MonitorsStudentsIndex": StudentsIndex,
        "DrivingInstructorsSessionsNew": StudentDevise,
        "DrivingInstructorsSessionsCreate": StudentDevise,
        "DeviseSessionsNew": StudentDevise,
        "DeviseSessionsCreate": StudentDevise,
    };

    constructor() {
        super();
        this.routes = Object.assign(this.routes, Routes.routes);
    }
}