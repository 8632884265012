require("@rails/ujs").start()
require("turbolinks").start()
//require("@rails/activestorage").start()
//require("channels")
const $ = window.$ = window.jQuery = require("jquery");
window.I18n = require("i18n-js");

//import "datatables.net-bs4/css/dataTables.bootstrap4.css"
//import "datatables.net-responsive-bs4/css/responsive.bootstrap4.css"
//import "datatables.net-buttons-bs4/css/buttons.bootstrap4.css"
//import "datatables.net-select-bs4/css/select.bootstrap4.css"

//import "dropzone/dist/dropzone.css"
//window.Dropzone = require("dropzone/dist/dropzone");

import "popper.js"
require("jquery-toast-plugin")
import "jquery-toast-plugin/dist/jquery.toast.min.css"
require("../monitors/utils/theme/pages/toastr.init")
//import "bootstrap-select/dist/css/bootstrap-select.css"
import "bootstrap"
import "../monitors/assets/styles/monitors.scss"

import "../monitors/utils/NativeConfirm.js"

const select2 = require('select2');
global.select2 = select2;
window.moment = require("moment");
//window.Chart = require("chart.js")


import Application from "../monitors/Application";

//import "../monitors/utils/plugins/dropify/dist/js/dropify"
//import "../monitors/utils/plugins/dropify/dist/css/dropify.min.css"

//import "daterangepicker/daterangepicker.css"
//import "daterangepicker/daterangepicker"
//import "bootstrap-colorpicker/dist/css/bootstrap-colorpicker.css"
//import "bootstrap-colorpicker"
//import "clockpicker/dist/bootstrap-clockpicker.css"
//require("clockpicker/dist/bootstrap-clockpicker")
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.css"
import "bootstrap-datepicker"


//require("bootstrap-select");
//require("datatables.net/js/jquery.dataTables")
//require("datatables.net-bs4/js/dataTables.bootstrap4.min")
//require("datatables.net-responsive/js/dataTables.responsive")
//require("datatables.net-responsive-bs4/js/responsive.bootstrap4.min")
//require("datatables.net-buttons/js/dataTables.buttons")
//require("datatables.net-buttons-bs4/js/buttons.bootstrap4.min")
//require("datatables.net-buttons/js/buttons.html5")
//require("datatables.net-buttons/js/buttons.flash")
//require("datatables.net-buttons/js/buttons.print")
//require("datatables.net-keytable/js/dataTables.keyTable")
//require("datatables.net-select/js/dataTables.select")
//require("pdfmake/build/pdfmake")
//require("pdfmake/build/vfs_fonts")

require('jquery-validation')


window.application = new Application();

require("../monitors/utils/theme/vendor");
require("../monitors/utils/theme/app");
$(document).on('turbolinks:load', function(event) {
    //require("../monitors/utils/theme/pages/form-pickers.init");

    $(document).on("submit", "form[method=get]", function(e) {
        Turbolinks.visit(this.action+(this.action.indexOf('?') === -1 ? '?' : '&')+$(this).serialize());
        return false;
    });

    Turbolinks.setProgressBarDelay(500);

    let $body = $("body");
    application.setController($body.data("controller"));
    application.setAction($body.data("action"));
    application.pageChanged();

    $.App.init();
    Waves.init();


    $(".copy_data").click(function (e){
        e.preventDefault();
        const el = document.createElement('textarea');
        el.value = $(this).data("copy");
        $(this).parent().append(el);
        el.select();
        document.execCommand('copy');
        $(this).parent().find(el).remove();

        let parent = $(this).parent();
        parent.data("content", I18n.t("common.copied"));
        parent.popover('show');
        setTimeout(function(){
            parent.popover('hide');
            parent.data("content", "");
        }, 2000);
        parent.on('hidden.bs.popover', function () {
            parent.popover('dispose');
        })
    })
});