export default class ContactTeamIndex {

    constructor() {
        this.validation = null;
        this.init();
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }

    init(){

        $.validator.addMethod("phone_number_fr", function( value, element ) {
            let val = value.replace(/ /g, '');
            return val.match(/[0-9]/g) && value.replace(/ /g, '').length === 10;
        }, I18n.t('common.js_validation.phone_number_fr'));

        let validation_options = {};

        if(I18n.locale !== 'en'){
            validation_options.messages = require("jquery-validation/dist/localization/messages_"+I18n.locale);
        }

        validation_options = {
            rules: {
                "business_notification[extras][phone]":{
                    required: true,
                    phone_number_fr: true
                }
            },
            messages: {
                "business_notification[extras][phone]": {
                    required: I18n.t('common.js_validation.phone_number_fr')
                }
            },
            errorClass: 'is-invalid',
            errorElement : 'div',
            errorLabelContainer: '.invalid-feedback',
            ignore: [],
            focusInvalid: false,
            invalidHandler: function(form, validator) {

                if (!validator.numberOfInvalids())
                    return;


                $('html, body').animate({
                    scrollTop: $(validator.errorList[0].element).parent().offset().top - 100
                }, 1000);

            }
        }

        if(I18n.locale !== 'en'){
            validation_options.messages = require("jquery-validation/dist/localization/messages_"+I18n.locale);
        }

        this.validation = $("#contact").validate(validation_options);

    }

    bindEvents() {
    }

    onDataChanged(data) {
    }

    onDestroy() {
    }
}