export default class StudentsIndex {

    constructor() {
        this.bindEvents();
    }

    bindEvents() {
      $("#exam_year").off("change").change(function(e) {
        e.preventDefault();
        Turbolinks.visit($(this).val());
      });
    }
}