import Planning from "../../common/utils/Planning";

export default class StudentsShow {

    constructor() {
        this.planning = new Planning();
        window.application.setOnDataChangeListener(this);
    }

    onDataChanged(data) {
        this.planning.onDataChanged(data);
    }

    onDestroy() {
    }
}