export default class StudentDevise {

    constructor() {
        this.bindEvents();
        this.validation = null;
        //window.application.setOnDataChangeListener(this);
    }

    bindEvents() {
        $('.js-toggle-password').click(function (e){
            e.preventDefault();
            let $el = $(this);
            if($el.hasClass('text-gray-600')){
                $el.removeClass('text-gray-600').addClass('text-gray-900');
                $el.parent().children('input[type=password]').attr('type', 'text');
            }else{
                $el.removeClass('text-gray-900').addClass('text-gray-600');
                $el.parent().children('input[type=text]').attr('type', 'password');
            }
        })

        $('#customer_address_postal_code').select2({
            minimumInputLength: 1,
            ajax: {
                contentType: "application/json",
                delay: 250,
                url: $('#customer_address_postal_code').data('url'),
                dataType: 'json',
                transport: function (params, success, failure) {
                    params['contentType'] = "application/json";
                    let $request = $.ajax(params);

                    $request.then(success);
                    $request.fail(failure);

                    return $request;
                }
            },
            theme: 'bootstrap'
        });

        $('label[for=customer_address_postal_code]').click(() => {
            $('#customer_address_postal_code').select2('open');
        })

        $(document).on('select2:open', () => {
            document.querySelector('.select2-search__field').focus();
        });

        this.setValidation();
    }

    onDataChanged(data) {
    }

    onDestroy() {
    }

    setValidation() {

        $.validator.addMethod("password_validation", function( value, element ) {
            return value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,70}$/g) && value.length >= 8;
        }, I18n.t('common.js_validation.password_validation'));

        let validation_options = {
            rules: {
                "customer[first_name]":{
                    required: true
                },
                "customer[last_name]":{
                    required: true
                },
                "customer[email]":{
                    required: true,
                    email: true
                },
                "customer[password]":{
                    required: true,
                    password_validation: true
                },
                "customer[address_postal_code]":{
                    required: true,
                    remote:  $('#customer_address_postal_code').data('urlvalidate')
                },

            },
            errorClass: 'is-invalid',
            errorElement : 'div',
            errorLabelContainer: '.invalid-feedback',
            ignore: [],
            focusInvalid: false,
            errorPlacement: (error, element) => {
                $(element).parent().append(error);
            }
        }

        if(I18n.locale !== 'en'){
            validation_options.messages = require("jquery-validation/dist/localization/messages_"+I18n.locale);
        }

        this.validation = $("#new_customer").validate(validation_options);
    }
}